.exDetail.main-page {
    overflow-y: scroll;

    .search-box {
        padding: 10px 5px 30px;

        .item {
            min-width: 280px;

            label {
                white-space: nowrap;
                line-height: 36px;
                margin-right: 15px;
                min-width: 70px;
            }
        }

        .btn-search {
            margin-right: 15px;
        }

        // .ant-col {
            // margin-bottom: 15px;
        // }

        .ant-input {
            width: 150px;
        }
    }

    .bread {
        padding: 20px 0 20px;
    }
}

.ant-input-group.ant-input-group-compact {
    width: 100%;
    display: flex !important;
}