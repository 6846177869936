.staff-page {
    width: 100%;
    min-height: 100%;
    position: relative;
    background: #fff;
    border-radius: 5px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    overflow: auto;

    .tree-container {
        width: 36%;
    }

    .table-container {
        width: 64%;
        padding: 16px;
        box-sizing: border-box;

        .bottom-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 24px;

            button {
                margin-right: 18px;
            }
        }
    }

    .ant-form-item-control-input {
        width: 200px;
    }

    .tree--item {
        margin-right: 10px;
        display: flex;
        .tree--label {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width:70px;
            margin-right: 10px;
        }
    }
}


.ant-modal-body {
    position: relative;
    overflow: auto;

    .common-input-style {
        margin-bottom: 18px;
        display: flex;
        line-height: 32px;

        .introudce {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: right;

            span {
                color: red;
            }
        }

        input {
            width: 200px;
        }
    }
}