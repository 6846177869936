.user-page {
    width: 100%;
    height: 100%;
    position: relative;
    background: #fff;
    overflow: auto;

    .userInfo {
        .img-style {
            width: 100%;
            display: flex;
            align-items: center;
            height: 64px;

            .avatar {
                width: 64px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                img {
                    width: 42px;
                    height: 42px;
                    display: block;
                }
            }
        }

        .text-info {
            width: 100%;
            line-height: 36px;
            padding: 0 12px;
            box-sizing: border-box;
        }
    }
}

.btn-container {
    width: 100%;
    height: 50px;
    position: relative;
    background: #f1f1f1;

    button {
        margin-top: 8px;
        margin-right: 12px;
        float: right;
    }
}

.tab-container {
    width: 100%;
    border-bottom: #f1f1f1 solid 1px;

    .text-info {
        width: 100%;
        line-height: 32px;
        padding: 0 12px;
        box-sizing: border-box;
    }
}

.ant-modal-body {
    position: relative;
    overflow: auto;

    .text-container {
        width: 100%;
        display: flex;

        .introudce {
            width: 160px !important;
            height: 32px;
            line-height: 32px;
            margin-bottom: 12px;

            span {
                color: red;
            }
        }

        .ant-input {
            width: 220px;
        }
    }
}