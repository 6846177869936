.login {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url(https://img.qupeiyin.cn/ugcdev/2020-07-21/accef8fec4e32a5832adbe26152917dd.jpg);
    background-size: 100%;

    .login-container {
        width: 520px;
        height: 360px;
        border: #fff solid 1px;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);

        .avatar-box {
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 36px;

            img {
                width: 72px;
                height: 72px;
                border-radius: 3px;
                z-index: 99;
            }
        }

        .input-box {
            display: flex;
            justify-content: center;
        }

        .ant-input-affix-wrapper {
            width: 360px;
            margin-bottom: 24px;
        }
        .login-btn {
            width: 360px;
        }
    }
}