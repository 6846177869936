.exClass.main-page {
    overflow-y: scroll;

    .main-page {
        overflow-y: scroll;
    }

    .flex {
        display: flex;
    }

    .search-box {
        padding: 30px 5px;

        .item {
            width: 360px;

            label {
                white-space: nowrap;
                line-height: 36px;
                margin-right: 15px;
            }
        }

        .btn-search {
            margin-right: 15px;
        }

        .ant-input {
            width: 150px;
        }
    }

    .avatar {
        width: 80px;
        height: 80px;
        background-color: #000;
    }

}

.ant-picker-range {
    width: 240px !important;

    input {
        width: 80px !important;
    }
}

// .ant-form-horizontal .ant-form-item-control {
//     width: 240px !important;
// }

.ant-picker-input {
    input {
        width: 80px !important;
    }
}