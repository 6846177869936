.home-page {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    .userInfo-top {
        width: 100%;
        background: #fff;
        display: flex;
        height: 100px;
        padding: 14px 18px;
        box-sizing: border-box;
        justify-content: center;
        .userInfo {
            width: 200px;
            height: 100%;
            text-align: center;
            .p1 {
                font-size: 22px;
                line-height: 36px;
                color: #666;
                margin: 0;
                span {
                    font-size: 16px;
                }
            }
            .p2 {
                font-size: 16px;
                line-height: 36px;
                color: #999999;
                margin: 0;
            }
        }
        .line {
            width: 1px;
            height: 100%;
            background: #999999;
            margin: 0 12px;
        }
    }
    .flex-container {
        margin-top: 20px;
        width: 100%;
        display: flex;
        position: relative;
        .left-container {
            width: 68%;
            .add-wx-flex {
                width: 100%;
                border-radius: 5px;
                height: 120px;
                background: #fff;
                display: flex;
                padding: 0 20px;
                box-sizing: border-box;
                .common-box {
                    width: 200px;
                    height: 100%;
                    border-right: #999999 1px solid;
                    p {
                        text-align: center;
                        display: block;
                        width: 100%;
                        margin: 0;
                        line-height: 40px;
                    }
                    .p1 {
                        font-size: 16px;
                        color: #666;
                    }
                    .p2 {
                        font-size: 16px;
                        color: #333;
                    }
                    .p3 {
                        font-size: 14px;
                        color: #666;
                        span {
                            font-size: 20px;
                        }
                    }

                }
            }
            .after-sale {
                width: 100%;
                border-radius: 5px;
                height: 120px;
                background: #fff;
                display: flex;
                padding: 0 20px;
                box-sizing: border-box;
                .common-box {
                    width: 200px;
                    height: 90px;
                    border-right: #999999 1px solid;
                    p {
                        text-align: center;
                        display: block;
                        width: 100%;
                        margin: 0;
                        line-height: 45px;
                    }
                    .p1 {
                        font-size: 16px;
                        color: #999999;
                    }
                    .p2 {
                        font-size: 24px;
                    }
                }
            }
            .class-table {
                width: 100%;
                margin-top: 20px;
                border-radius: 6px;
                overflow: hidden;
                .title-container {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 18px;
                    box-sizing: border-box;
                    background: #fff;
                }
            }
        }
        .right-container {
            width: 30%;
            background: #fff;
            border-radius: 6px;
            .title-container {
                border-radius: 6px;
                width: 100%;
                height: 60px;
                line-height: 60px;
                padding: 0 18px;
                box-sizing: border-box;
                background: #fff;
            }
        }
    }
}