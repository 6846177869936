.not-found {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    font-size: 56px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
}