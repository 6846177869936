.teacher-page {
    width: 100%;
    background: #fff;
    min-height: 100%;
    border-radius: 5px;
    overflow: auto;

    .btn-box {
        width: 100%;
        height: 60px;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 24px;
        }
    }

    .flex-container {
        display: flex;
        width: 100%;
        height: auto;
        overflow: auto;

        .left-container {
            width: 24%;
            padding: 20px;
            box-sizing: border-box;
        }

        .right-container {
            width: 76%;
            padding: 20px;
            box-sizing: border-box;
        }
    }

    .tree--label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 70px;
        margin-right: 10px;
    }

}


.t-input {
    width: 200px !important;
}

.ant-modal-body {
    position: relative;
    .t-input {
        width: 200px !important;
    }
    .ant-form-item-control-input {
        width: 200px !important;
    }

    .common-input-style {
        margin-bottom: 18px;
        display: flex;
        line-height: 32px;

        .introudce {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: right;

            span {
                color: red;
            }
        }

        .introudce-name {
            width: 100px;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                color: red;
            }
        }

        .show-name {
            width: auto;
            line-height: 32px;
        }

        .introudce-upload {
            width: 100px;
            height: 102px;
            line-height: 102px;
            text-align: right;

            span {
                color: red;
            }

            .ant-upload-picture-card-wrapper {
                width: 50%;
            }
        }

    }

    .ant-form-item-control-input-content{
        width: 100%;
        position: relative;
        overflow: hidden;
        img {
            height: auto;
            width: 100px;
        }
    }
}

.avatar-uploader>.ant-upload {
    width: 128px;
    height: 128px;
}

// .ant-upload-picture-card-wrapper {
//     // width: 50% !important;
// }