.tag-page {
    width: 100%;
    height: 100%;
    position: relative;
    background: #fff;
    border-radius: 5px;
    padding: 16px;
    box-sizing: border-box;
    overflow: auto;
    .add-group {
        width: 100%;
        height: 72px;
        position: relative;
        background: #fff;
        margin-top: -20px;
        .add-btn {
            float: right;
            right: 0;
            top: 12px;
            margin-left: 12px;
        }
        .back-btn {
            float: left;
            left: 0;
            top: 12px;
            margin-left: 12px;
        }
    }
    .group-list {
        position: relative;
        width: 100%;
        border-bottom: #e5e5e5 solid 1px;
        p {
            font-size: 24px;
            line-height: 32px;
            margin: 0;
        }
    }
}

.ant-tag {
    margin-bottom: 6px !important;
}


.ant-modal-body {
    position: relative;
    overflow: auto;
    .text-container {
        width: 100%;
        display: flex;
        .introudce {
            width: 160px !important;
            span {
                color: red;
            }
        }
    }
    .tag-container {
        width: 100%;
        height: 240px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        .tag-del {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}