.ant-layout-sider-children {
    position: relative;
    .fix-theme {
        width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: 0;
    }
}

.ant-layout-header {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .userInfo {
        width: 200px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-around;
        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 3px #fff solid;
        }
    }
}
.flex {
    display: flex;
}
.justfiy-content-betweent{
    justify-content: space-between;
}
.align-item-center{
    align-items: center;
}
