.role-page {
    width: 100%;
    background: #fff;
    height: 100%;
    overflow-y: auto;
    border-radius: 5px;

    .btn-box {
        width: 100%;
        height: 60px;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 24px;
        }
    }

    .flex-container {
        display: flex;
        width: 100%;
        height: auto;
        overflow: auto;

        .left-container {
            width: 24%;
            padding: 20px;
            box-sizing: border-box;

            .list-box {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 42px;
                border-bottom: #e5e5e5 1px solid;
                cursor: pointer;

                .role-name {
                    width: 100px;
                    text-align: center;
                }

                .seat-box {
                    width: 18px;
                }

                button {
                    margin-right: 12px;
                }

                &.active {
                    color: rgb(0, 217, 255);
                }
            }

            .add-box {
                width: 100%;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                button {
                    border: none;
                }
            }
        }

        .right-container {
            width: 76%;
            padding: 20px;
            box-sizing: border-box;
            .flex-box {
                margin-bottom: 16px;
                .sub-box {
                    margin: 16px 36px;
                    .function-box {
                        margin: 10px 24px;
                    }
                }
            }
        }
    }
}