.board.data-page{
    overflow-x:hidden;
    p {
        padding: 0 !important;
        margin: 0 !important;
    }
    .middle {
        .ant-card-body {
            padding: 24px 24px 15px;
        }

        .header {
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 20px;
        }

        .content {
            padding-top: 10px;
        }
    }
    .content-box{
        background-color: #fff;
        margin-top: 30px;
        padding:24px;
        box-sizing: border-box;
        .item {
            min-width: 280px;
            padding:0 10px 0;
            label {
                white-space: nowrap;
                line-height: 36px;
                margin-right: 15px;
                min-width: 70px;
            }
        }
    }
    .ant-space-item{
        margin: 0!important;
    }
}