.menu-page {
    width: 100%;
    height: 100%;
    position: relative;
    background: #fff;
    border-radius: 5px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    overflow: auto;

    .tree-container {
        width: 36%;
    }

    .table-container {
        width: 64%;
        padding: 16px;
        box-sizing: border-box;
        .bottom-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            button {
                margin-right: 18px;
            }
        }
    }
}

.ant-modal-body {
    position: relative;
    overflow: auto;
    .common-input-style {
        margin-bottom: 18px;
        display: flex;
        line-height: 32px;
        .introudce-auth {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: right;
            margin-right: 16px;
            span {
                color: red;
            }
        }
        input {
            width: 200px;
        }
    }
}