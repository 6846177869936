.user-page {
    width: 100%;
    height: 100%;
    position: relative;
    background: #fff;
    overflow: auto;
    .teacher-list {
        width: 100%;
        line-height: 50px;
        padding: 0 12px;
        box-sizing: border-box;
        border-bottom: #e5e5e5 solid 1px;
        .name {
            font-size: 24px;
            margin-right: 12px;
        }
    }
}