.main-page {
  width: 100%;
  padding: 0 20px;
  background: #fff;
  height: 100%;
  border-radius: 5px;
  overflow: auto;

  .box {
    width: 100%;
    height: 20px;
  }

  // input {
  //   width: 150px;
  // }


  .header-search {
    width: 100%;
  }
}

.ant-modal-body {
  position: relative;

  .common-input-style {
    margin-bottom: 18px;
    display: flex;
    line-height: 32px;

    .introudce {
      width: 100px;
      height: 32px;
      line-height: 32px;
      text-align: right;

      span {
        color: red;
      }
    }

    .introudce-name {
      width: 100px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        color: red;
      }
    }

    input {
      width: 200px;
    }

    textarea.ant-input {
      /* max-width: 100%; */
      width: 240px;
      height: auto;
      min-height: 32px;
      line-height: 1.5715;
      vertical-align: bottom;
      -webkit-transition: all 0.3s, height 0s;
      transition: all 0.3s, height 0s;
    }
  }
}

// .ant-form-item-control {
//   width: 120px !important;
// }

.ant-form-item-control-input {
  width: 120px !important;
}
.ant-form-horizontal .ant-form-item-control {
  width: auto !important;
}