.data-page {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 3px;
    position: relative;

    .achieve-content {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        .common-achieve {
            width: 23%;
            background: #fff;
            padding: 12px;
            box-sizing: border-box;
            margin-bottom: 28px;
            margin-right: 2%;
            .achieve-title {
                font-size: 16px;
                color: #999999;
                display: flex;
                justify-content: space-between;
            }
            .achieve-center {
                font-size: 24px;
                color: #333;
                height: 60px;
                border-bottom: #e5e5e5 1px solid;
            }
            .achieve-bottom {
                font-size: 16px;
                line-height: 30px;
                color: #666666;
            }
        }
    }

    .achieve-echart {
        width: 100%;
        padding: 16px;
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .left-echart {
            width: 70%;
            overflow: auto;
        }
        .right-list {
            width: 28%;
            overflow: auto;
            .p-title {
                width: 100%;
                text-align: left;
                line-height: 42px;
                font-weight: 600;
            }
            .rank-list {
                width: 100%;
                padding: 0 12px;
                box-sizing: border-box;
                display: flex;
                .rank-index {
                    width: 20px;
                    height: 20px;
                    margin-right: 20px;
                    border-radius: 50%;
                    background: #666666;
                    color: #fff;
                    text-align: center;
                    line-height: 20px;
                    &.active {
                        background: #e5e5e5;
                        color: #333;
                    }
                }
                .rank-name {
                    width: 160px;
                    height: 20px;
                    line-height: 20px;
                }
            }
        }
    }

    .table-content {
        width: 100%;
        padding: 16px;
        background-color: #fff;
        box-sizing: border-box;
    }
}