.overview.data-page {
    overflow-x:hidden;
    p {
        padding: 0 !important;
        margin: 0 !important;
    }
    .middle {
        .ant-card-body {
            padding: 24px 24px 15px;
        }

        .header {
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 20px;
        }

        .content {
            padding-top: 10px;
        }
    }

    .large {
        margin-bottom: 20px;

        .ant-card-body {
            padding: 0 !important;
        }

        .content {
            padding: 24px;
        }

        .footer {
            border-top: 1px solid #f0f0f0;
            padding: 24px;
        }
        .detail{
            width:150px
        }
    }

    .opacity-0 {
        opacity: 0;
    }

    .font-size-36 {
        font-size: 36px;

    }
    .rank{
        padding-inline-start:0;
        .header{
            list-style: none;
            padding-bottom: 10px;
        }
        .item{
            list-style: none;
            padding: 10px 0;
        }
    }
    .ant-space-item{
        margin: 0!important;
    }
}