.login-page {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #2d3a4b;
    overflow: auto;
    // background-image: url(https://peiyinimg.qupeiyin.cn/1612237737107-967.jpg);
    background-size: 100%;
    .login-info {
        width: 300px;
        height: 200px;
        padding: 24px 0;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 16px;
        .text-input {
            width: 100%;
            padding: 0 12px;
            box-sizing: border-box;
            display: flex;
            margin-top: 12px;
            line-height: 32px;
        }
        .text-login {
            width: 100%;
            margin-top: 12px;
            display: flex;
            justify-content: center;
        }
    }
}