.user-details {
    width: 100%;
    background: #fff;
    height: 100%;
    border-radius: 5px;
    display: flex;
    overflow: auto;

    .left-info {
        width: 30%;
        height: 100%;
        border-right: #e9e9e9 1px solid;

        .avatar-box {
            width: 100%;
            height: 100px;
            margin: 32px 0 30px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .avatar {
                width: 80px;
                height: 80px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }

                .vip-tag {
                    width: 32px;
                    height: 20px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background: #e70000;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    border-radius: 10px;
                }
            }
        }

        .common-style {
            width: 100%;
            height: 42px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            color: #666666;

            .introduce-title {
                width: 100px;
                height: 42px;
                line-height: 42px;
                text-align: right;
                margin-right: 12px;
                color: #333;
            }

            input {
                width: 180px;
            }
        }

        .flex-btn {
            width: 100%;
            padding: 0 24px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            button {
                margin-right: 12px;
            }
        }
    }

    .right-info {
        width: 70% !important;
        padding: 18px;
        box-sizing: border-box;
        height: 100%;
        .flex-btn {
            width: 100%;
            padding: 0 24px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            button {
                margin-right: 12px;
            }
        }
        .level-container {
            min-width: 420px;
            padding: 12px 16px;
            box-sizing: border-box;
            border-bottom: 1px #e5e5e5 solid;
            .level-list {
                width: 100%;
                line-height: 36px;
                display: flex;
                justify-content: space-between;
                .level-left {
                    width: 50%;
                    text-align: left;
                }
                .level-right {
                    width: 50%;
                    text-align: left;
                }
                .information {
                    width: 100%;
                }
            }
        }
    }
}

.ant-modal-body {
    position: relative;
    overflow: auto;
    .common-input-style {
        margin-bottom: 18px;
        display: flex;
        line-height: 32px;
        .introudce {
            width: 160px !important;
            height: 32px;
            margin-right: 24px;
            line-height: 32px;
            text-align: right;
            span {
                color: red;
            }
        }
        input {
            width: 240px;
        }
    }
}

.MyModal {
    width: 640px !important;
}